import React from "react";
import { Link } from "react-router-dom";
import Helper from "../Helper/helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import configuration from "react-global-configuration";

import { translate } from "react-multi-lang";
import PlanCard from "../NewUI/PlanCard"
import { CardExpiryElement, CardNumberElement, CardCvcElement } from '@stripe/react-stripe-js';
import { withRouter } from 'react-router-dom';



var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
class SubscriptionForm extends Helper {

  constructor(props) {
    super(props)

    this.SetChosenPlan = this.SetChosenPlan.bind(this)
   
  }
  
  

  SetChosenPlan(plan) {
    this.handlePromoCodeRemove();
    this.setState({ chosenPlan: plan })
    this.setState({ data:{ coupon_code: "" } })

    if (plan.amount > 10) {
      this.setState({ isAnnual: true });
    } else {
       this.setState({ isAnnual: false });
    }

      
  }
  

  state = {
    data: {
      email: "",
      password: "",
      name: "",
      timezone: const_time_zone,
      referral_code: "",
      nameOnCard: localStorage.getItem("username") ? localStorage.getItem("username") : "",
      postalCode: "",
      coupon_code:"",
    },
   
    loadingContent: null,
    buttonDisable: false,
    check_referral_response: "",

    subscriptions: [],
    loading: true,
    accessToken: false,
    total: 0,
    
    promoCode: null,
    loadingPromoCode: true,
    paymentMode: "card",
    loadingContentCard: null,
    buttonDisableCard: false,
    pay_amount: 0,
    chosenPlan: null,
    isAnnual: true,

    user_id: null,
    token: null,
  };



  componentDidMount() {

    const data = {
      sub_profile_id: "",
        token: localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
        id:  localStorage.getItem("userId") ? localStorage.getItem("userId") : 1
      };

        api.postMethod("subscription_plans", data).then(response => {
            if (response.data.success === true) {
                let subscriptions = response.data.data;
              this.setState({ loading: false, subscriptions: subscriptions, chosenPlan: subscriptions[1] });
              
              if (localStorage.getItem("accessToken")) {
                this.setState({ accessToken: true } );
              } else {
                this.setState({ accessToken: false } );
              }

            } 
        });
  }

  

  signupForm() {
     
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data } = this.state;
    const { t } = this.props;
    return (
      <div>
        
      </div>
    )
  }

 

  paymentForm() {
    const { subscriptions } = this.state;
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data , couponeData} = this.state;
    const { t } = this.props;

    const { loadingPromoCode, promoCode, paymentMode, chosenPlan, isAnnual } = this.state;
    
    
    
    return (
      <div>
        <div className="common-bg-img" style={bgImg}>
        
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                
                <h3 className="register-box-head free_acount">Activate Your Account</h3>
              
                {/* plans */}
             
                       
                {/* {alert(isAnnual)} */}
                {isAnnual ?
                  <div className="row">
                    <PlanCard plan={subscriptions[1]} chosenPlan={this.SetChosenPlan} active="true"></PlanCard>
                    <PlanCard plan={subscriptions[0]} chosenPlan={this.SetChosenPlan}  active="false"></PlanCard>
                  </div> : <div className="row">
                    <PlanCard plan={subscriptions[1]} chosenPlan={this.SetChosenPlan} active="false"></PlanCard>
                    <PlanCard plan={subscriptions[0]} chosenPlan={this.SetChosenPlan}  active="true"></PlanCard>
                  </div>
                  }
                              
                             
                              
     
       

                
              
                {/* <div className="register-box"> */}
                <div className="stripe-payment">
                  <div className="stripe-item ">
                    <a href="https://stripe.com/" target="_blank"><img src="assets/img/stripe.png" className="stipe-logo" alt="stripe"/></a>                             
                  <p className="payment-paragraph">Secure Information</p>
                  </div>
                  <div >                  
                    <ul className="card-list">
                      <li className="card-visa visa"></li>
                      <li className="card-mastercard visa"></li>
                      <li className="card-amex visa"></li>
                      {/* <li class="card-discover visa"></li> */}
                    </ul>
                  </div>
                </div>

                 
                <form className="auth-form" onSubmit={this.addCard}>
     
                    <label htmlFor="name">Name On Card</label>
                    <input
                      onChange={this.handleChange}
                      className="inputCardName"
                      id="nameOnCard"
                    name="nameOnCard"
                    placeholder='Name'
                      value={data.nameOnCard}
                    />
    
                  
                  {/* <CardElement></CardElement> */}
                  <label htmlFor="cardNumber">Card Number</label>
                  <CardNumberElement
                    options={{
                       style: {
                        base: {
                          fontSize: '18px',
                          color: '#000',
                          letterSpacing: '0.025em',
                          '::placeholder': {
                            color: 'gray',
                          },
                        },
                        invalid: {
                          color: 'red',
                        },
                      },
                    }}
                  ></CardNumberElement>
                  <div className="row">
                    <div className="col-4">
                    <label htmlFor="cardNumber">Expiration</label>
                    <CardExpiryElement
                     options={{
                       style: {
                        base: {
                          fontSize: '18px',
                          color: '#000',
                          letterSpacing: '0.025em',
                          '::placeholder': {
                            color: 'gray',
                          },
                        },
                        invalid: {
                          color: 'red',
                        },
                      },
                        }}></CardExpiryElement>
                    </div>
                    <div className="col-4">
                    <label htmlFor="cardNumber">CVC</label>
                    <CardCvcElement
                       options={{
                         style: {
                          base: {
                            fontSize: '18px',
                            color: '#000',
                            letterSpacing: '0.025em',
                            '::placeholder': {
                              color: 'gray',
                            },
                          },
                          invalid: {
                            color: 'red',
                          },
                        },
                      }}
                    ></CardCvcElement>
                    </div>
                    <div className="col-4">
                      <label htmlFor="name">Postal Code</label>
                        <input
                          onChange={this.handleChange}
                          className="inputCardName"
                          id="postalCode"
                        name="postalCode"
                        placeholder='Postal Code'
                          value={data.postalCode}
                        />
                    </div>
                  </div>
                  
                   {/* <!-- coupon --> */}
                  <div className="mt-4">
                    {/* <label htmlFor="name">Promo Code</label> */}
                        {/* <h5 className="capitalize"></h5> */}
                        {/* <form id="paymentForm"
                          className="auth-form"
                          onSubmit={this.handlePromoCode}
                        > */}
                          <div className="form-group mt-3">
                            <div className="input-group mb-3 mt-1">
                              <input
                                type="text"
                                className="form-control m-0 mb-0 coupon"
                                placeholder="Enter promo code"
                                name="coupon_code"
                                value={data.coupon_code}
                                onChange={this.checkCoupon}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-danger"
                            type="button"
                            onClick={this.handlePromoCode}
                                >
                                  {this.state.loadingContent != null
                                    ? this.state.loadingContent
                                    : "apply"}
                                </button>
                              </div>
                            </div>
                          </div>
                        {/* </form> */}
                        {loadingPromoCode ? (
                          ""
                        ) : (
                          <p className="capitalize">
                            {t("promo_code_applied")} - {promoCode.coupon_code}{" "}
                            for {promoCode.original_coupon_amount} -{" "}
                            <Link
                              to="#"
                              className="btn btn-outline-danger"
                              onClick={this.handlePromoCodeCancel}
                            >
                              {t("remove")}
                            </Link>
                          </p>
                        )}
                      </div>
                    
                  {/* <!-- coupon --> */}
                  

                    {/* <!-- table1 --> */}
                    <div className="table-responsive details">
                      <table className="table white-bg m-0 mt-3">
                        <tbody>
                          <tr className="table-secondary">
                            <td>{t("amount")}</td>
                            <td>
                              {chosenPlan.currency}
                              {chosenPlan.amount}
                            </td>
                          </tr>
                          {chosenPlan.amount > 0 ? (
                            <tr>
                              <td>{t("promo_code_amount")}</td>
                              <td>
                                {chosenPlan.currency}
                                {loadingPromoCode
                                  ? "0"
                                  : promoCode.coupon_amount}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        
                          <tr className="table-secondary">
                            <td>{t("total")}</td>
                            <td>
                              {chosenPlan.currency}
                              {loadingPromoCode ? chosenPlan.amount : chosenPlan.amount -  promoCode.coupon_amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <!-- table --> */}



                  <button
                    className="btn btn-danger auth-btn"

                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : loadingPromoCode ? "Pay $"+ chosenPlan.amount : "Pay $"+ Math.round((chosenPlan.amount -  promoCode.coupon_amount))}
                  </button>
                </form>
             

                {/* <p className="auth-link">
                  By creating an account you confirm that you’ve read, understand and agree to our <a className="btn-link" href="https://wrestlinglegendsnetwork.tv/page/terms">Terms of Use</a> and <a className="btn-link" href="https://wrestlinglegendsnetwork.tv/page/privacy">Privacy Policy</a>.
                 
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
   renderSubscription = subscriptions => {
        return (
            <React.Fragment>
                {subscriptions.map(subscription => (
                    <div
                        className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
                        key={subscription.subscription_id}
                  >
                    <PlanCard plan={subscription}></PlanCard>
                    {/* <PlanCard2></PlanCard2> */}
                        {/* <div className="subcsription-card">
                            <div className="subcsription-head">
                                {subscription.title}
                            </div>
                            <div
                                className={
                                    "subcsription-price" +
                                    (subscription.popular_status == 1
                                        ? " premium"
                                        : "")
                                }
                            >
                                <p>plan</p>
                                <h4>
                                    {subscription.currency}
                                    {subscription.amount} / {subscription.plan}{" "}
                                    {translate("month")}
                                </h4>
                            </div>
                            <div className="subcsription-details">
                                <h4>maintain account</h4>
                                <h5>
                                    <i className="fas fa-user-plus" />
                                    {subscription.no_of_account}
                                </h5>
                                <p>{subscription.description}</p>
                                <div className="text-right mt-4">
                                    <Link
                                        to={{
                                            pathname: "/invoice",
                                            state: {
                                                subscription: subscription
                                            }
                                        }}
                                        className="btn btn-danger"
                                    >
                                        pay now
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                ))}
            </React.Fragment>
        );
    };

  
  handleSubmitPayment = (event) => {
  }

  // COUPON fUNCTYIONS
  checkCoupon = (e) => {

       this.setState({ data: { coupon_code: e.target.value } });
    
  };

  handlePromoCode = (event) => {
    event.preventDefault();
    this.setState({
      loadingContent: translate("loading_text"),
    });
    let inputData = {
      coupon_code: this.state.data.coupon_code,
      subscription_id: this.state.chosenPlan.subscription_id,
      token: localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
      id:  localStorage.getItem("userId") ? localStorage.getItem("userId") : 1
    };
    api
      .postMethod("apply/coupon/subscription", inputData)
      .then((response) => {
        if (response.data.success) {
          // ToastDemo(
          //   this.props.toastManager,
          //   translate("promo_code_applied_success"),
          //   "success"
          // );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            loadingPromoCode: false,
            promoCode: response.data.data,
          });
         
          this.setState({
            loadingContent: null,
            buttonDisable: false,
            pay_amount: response.data.data.remaining_amount,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  
  handlePromoCodeCancel = (event) => {
    event.preventDefault();
    this.setState({ promoCode: null, loadingPromoCode: true });
   
  };

   handlePromoCodeRemove = () => {
    this.setState({ promoCode: null, loadingPromoCode: true });
    
  };
  // Strip card

  addCard  = async (ev) => {
    ev.preventDefault();

     if (!this.props.stripe || !this.props.elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
     }
    
    const card = this.props.elements.getElement(CardNumberElement);

    // const stripe = this.props.stripe;

 
    // const payloadToken = await stripe.createToken(card, {
    //   name: this.state.data.nameOnCard
    // });

    // if (payloadToken.error) {
    //   console.log('[error]', payloadToken.error);
    //   this.setState({
    //     errorMessage: payloadToken.error.message,
    //     paymentMethod: null,
    //   });
    // } else {
    //   console.log(payloadToken);
    // }

    
    this.setState({
      loadingContent: translate("loading_text"),
      // buttonDisable: true,
    });
    if (this.props.stripe) {
     await this.props.stripe
        .createToken(card,{
          type: "card",
          name: localStorage.getItem("username") ? localStorage.getItem("username") : "",
        })
        .then((payload) => {
          if (payload.error) {
            ToastDemo(this.props.toastManager, payload.error.message, "error");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
              openPopup: false,
            });
          } else {
            const inputData = {
              card_token: payload.token.id,
              token: localStorage.getItem("accessToken") ? localStorage.getItem("accessToken") : "2y10Gv633zOPmq7IloFoF3HS5eRCLs3kqqeTjBfvNuU2XafLXApjW9Cy",
              id:  localStorage.getItem("userId") ? localStorage.getItem("userId") : 1
            };
            api
              .postMethod("payment_card_add", inputData)
              .then((response) => {
                if (response.data.success) {
                  // ToastDemo(
                  //   this.props.toastManager,
                  //   response.data.message,
                  //   "success"
                  // );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                  });
                  this.handlePayment();
                } else {
                  ToastDemo(
                    this.props.toastManager,
                    response.data.error_message,
                    "error"
                  );
                  this.setState({
                    loadingContent: null,
                    buttonDisable: false,
                  });
                }
              })
              .catch((error) => {
                ToastDemo(this.props.toastManager, error, "error");
                this.setState({
                  loadingContent: null,
                  buttonDisable: false,
                });
              });
          }
        });
    } else {
      ToastDemo(
        this.props.toastManager,
        "Stripe.js hasn't loaded yet.",
        "error"
      );
      this.setState({ loadingContent: null, buttonDisable: false });
    }
  };

  handlePayment = () => {
    this.setState({
      loadingContentCard: translate("loading_text"),
      buttonDisableCard: true,
    });
    let inputData;
    if (this.state.promoCode == null) {
      inputData = {
        subscription_id: this.state.chosenPlan.subscription_id,
        payment_mode: this.state.paymentMode,
      };
    } else {
      inputData = {
        coupon_code: this.state.data.coupon_code,

        subscription_id: this.state.chosenPlan.subscription_id,
        payment_mode: this.state.paymentMode,
      };
    }
    this.paymentApiCall(inputData);
  };

  paymentApiCall = (inputData) => {
    api
      .postMethod("v4/subscriptions_payment", inputData)
      .then((response) => {
        if (response.data.success) {
          // ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
          this.props.history.push("/view-profiles");
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContentCard: null,
            buttonDisableCard: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({
          loadingContentCard: null,
          buttonDisableCard: false,
        });
      });
  };

  render() {
    return (
      <div>
        {this.state.accessToken === true ? this.paymentForm() : this.signupForm()}
    </div>
          
    );
  }
}

export default withRouter(withToastManager(translate(SubscriptionForm)));
