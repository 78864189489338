import React, { Component } from "react";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import MediaQuery from "react-responsive";

const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: this.props.data,
    };
  }

  componentDidMount() {
    var headerHeight = $("#header").outerHeight();

    $(".header-height").height(headerHeight);

    // Call api function
  }

  render() {
    return (
      <>
        <MediaQuery minDeviceWidth={1224}>
          <div className="nav-wrapper">
            <nav
              className="navbar navbar-expand navbar-dark new-main-nav "
              id="header"
            >
              <Link className="navbar-brand" to="/home">
                <img
                  src={configuration.get("configData.site_logo")}
                  className="logo-img-new "
                  alt="logo_img"
                />
              </Link>
              <div className="btn-container">
                <a href="/login" className="btn btn-danger btn-18">
                  Login
                </a>
                <a
                  href="#"
                  className="btn btn-danger btn-sub"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </nav>
            <div className="header-height"></div>
          </div>
          {/* modal */}
          <div className="modal fade confirmation-popup" id="cancel-subs">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form
                  className="auth-form cancel-form"
                  onSubmit={this.handleCancelAutoRenewal}
                >
                  <div className="modal-header">
                    <h4 className="modal-title">Start Your Free Trial</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="form-group">
                      <p>
                        World One TV is Free for 30 days just use the promo code{" "}
                        <div className="coupone-container">
                          <span className="coupon"> FREE30DAYS </span>
                        </div>
                        <br />
                        After that it’s $2.99 a Month or $39.99 for the year
                      </p>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <a
                      href="/subscribe-with-Stripe"
                      className="btn btn-danger "
                    >
                      Start Your Free Trial
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end modal */}
        </MediaQuery>

        <MediaQuery maxDeviceWidth={1224}>
          <div className="nav-wrapper-cel">
            <nav
              className="navbar navbar-expand navbar-dark new-main-nav-cel "
              id="header"
            >
              <Link className="navbar-brand" to="/home">
                <img
                  src={configuration.get("configData.site_logo")}
                  className="logo-img-new-cel "
                  alt="logo_img"
                />
              </Link>
              <div className="btn-container-cel">
                <a href="/login" className="btn btn-danger btn-13">
                  Login
                </a>
                <a
                  href="#"
                  className="btn btn-danger btn-sub btn-13"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </nav>
            <div className="header-height"></div>
          </div>
          {/* modal */}
          <div className="modal fade confirmation-popup" id="cancel-subs">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form
                  className="auth-form cancel-form"
                  onSubmit={this.handleCancelAutoRenewal}
                >
                  <div className="modal-header">
                    <h4 className="modal-title">Start Your Free Trial</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="form-group">
                      <p>
                        World One TV is Free for 30 days just use the promo code{" "}
                        <div className="coupone-container">
                          <span className="coupon"> FREE30DAYS </span>
                        </div>
                        <br />
                        After that it’s $2.99 a Month or $39.99 for the year
                      </p>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <a
                      href="/subscribe-with-Stripe"
                      className="btn btn-danger "
                    >
                      Start Your Free Trial
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end modal */}
        </MediaQuery>
      </>
    );
  }
}

export default Header;
