import React, { Component } from "react";
import Header from "../Layouts/SubLayout/StaticHeader";
import ReactPlayer from "react-player";
import MediaQuery from "react-responsive";

class LandingPage extends Component {
  render() {
    return (
      <>
        <Header></Header>
        <MediaQuery minDeviceWidth={1224}>
          <div>
            {/* section 1 */}
            <div className="new-main-sec1">
              <div className="new-hero">
                <img src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/WorldOnePic1PC.jpg"></img>
              </div>
              <div className="sh-new-hero"></div>

              {/* banner info */}
              <div className="new-hero-text">
                <div className="new-hero-text-container">
                  <h1>Connecting Cultures and Celebrating Voices</h1>
                  <div>
                    <p>
                      Watch popular independent movies, new and old Black
                      Cinema, documentaries and International Cinema from the
                      Black Diaspora.
                    </p>
                  </div>
                </div>
                <div className="">
                  <a
                    href="#"
                    className="btn btn-danger "
                    data-toggle="modal"
                    data-target="#cancel-subs"
                  >
                    Start Your Free Trial
                  </a>
                </div>
              </div>
              {/* <div className="new-hero-text">
                <div className="new-hero-flex-text">
                  {" "}
                  <h1>Connecting Cultures and Celebrating Voices</h1>
                  <p>
                    Watch popular independent movies, new and old Black Cinema,
                    documentaries and International Cinema from the Black
                    Diaspora.
                  </p>
                </div>
                <div className="new-hero-flex-btn">
                  <a
                    href="#"
                    className="btn btn-danger "
                    data-toggle="modal"
                    data-target="#cancel-subs"
                  >
                    Start Your Free Trial
                  </a>
                </div>
              </div> */}
            </div>
            {/* section 2 */}
            <div className="new-main-sec2">
              <div className="sec2-main">
                <div className="sec2-image">
                  <img src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/wide2sean.png" />
                </div>
                <div className="sec2-text">
                  <h1>New Releases Every Week</h1>
                  <p>
                    Watch Drama, Documentaries, Music Specials, Faith and
                    Family. All Day, Everyday.
                  </p>
                  <a
                    href="#"
                    className="btn btn-danger "
                    data-toggle="modal"
                    data-target="#cancel-subs"
                  >
                    Start Your Free Trial
                  </a>
                </div>
              </div>
            </div>
            {/* section 3 */}
            <div className="new-main">
              <div className="sec3-main">
                <h1>World One TV</h1>
                <div className="sec3-info">
                  <div className="sec3-info-des">
                    <div className="line"></div>
                    <h1>Black Owned </h1>
                    <p>By us, for us</p>
                    <p>Streaming diversity</p>
                    <p>One Click Away</p>
                    <div className="line"></div>
                  </div>
                  <div className="sec3-info-des">
                    <div className="line"></div>
                    <h1>Open Your Mind </h1>
                    <p>
                      Curated to educate, entertain and engage, with enriching
                      content.
                    </p>
                    <div className="line"></div>
                  </div>
                  <div className="sec3-info-des">
                    <div className="line"></div>
                    <h1>Anytime, Anywhere</h1>
                    <p>
                      Download World One TV app on your Android and Apple
                      devices.
                    </p>
                    <p>Apple, Fire TV, Roku and Google.</p>

                    <div className="line"></div>
                  </div>
                </div>

                <div className="sec3-img">
                  <img
                    src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/devices.png"
                    width="780"
                    height="500px"
                  />
                </div>
                <a
                  href="#"
                  className="btn btn-danger"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </div>
            {/* section 4 */}
            <div className="new-main-sec2">
              <div className="sec2-main sec4-flex">
                <div className="sec2-image">
                  <img
                    src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/CourtsideJonesReplacementImage.jpg"
                    width="480px"
                    height="270px"
                  />

                  {/* <video
                  data-account="3392051363001"
                  data-player="default"
                  data-embed="default"
                  data-application-id=""
                  class="vjs-tech"
                  id="vjs_video_3_html5_api"
                  tabindex="-1"
                  poster="https://cf-images.us-east-1.prod.boltdns.net/v1/static/3392051363001/a54cf032-d534-460a-a4c8-5a4d9cfbbd2c/65aa704e-91e4-42a1-8cd0-237c4ecf8a1d/1280x720/match/image.jpg"
                  src="blob:https://players.brightcove.net/baeb28a7-d83a-460e-bf59-652f0d661717"
                ></video> */}
                </div>
                <div className="sec2-text sec4-full">
                  <h1>Watch the World </h1>
                  <p>
                    World One TV is Free for 30 Days,
                    <br />
                    use the promo code FREE30DAYS on our website.
                    <br />
                    After that it's $2.99 a month or $39.99 annually. <br />
                    Always Ad free on WorldOneTV.com.
                  </p>
                  <a
                    href="#"
                    className="btn btn-danger"
                    data-toggle="modal"
                    data-target="#cancel-subs"
                  >
                    Start Your Free Trial
                  </a>
                </div>
              </div>
            </div>
            {/* section 5 */}
            <div></div>
            {/* end */}

            {/* modal */}
            <div className="modal fade confirmation-popup" id="cancel-subs">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <form
                    className="auth-form cancel-form"
                    onSubmit={this.handleCancelAutoRenewal}
                  >
                    <div className="modal-header">
                      <h4 className="modal-title">Start Your Free Trial</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="form-group">
                        <p>
                          World One TV is Free for 30 days just use the promo
                          code
                          <div className="coupone-container">
                            <span className="coupon"> FREE30DAYS </span>
                          </div>
                          <br />
                          After that it’s $2.99 a Month or $39.99 for the year
                        </p>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <a
                        href="/subscribe-with-Stripe"
                        className="btn btn-danger "
                      >
                        Start Your Free Trial
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* end modal */}
          </div>
        </MediaQuery>

        {/* mobile */}
        <MediaQuery maxDeviceWidth={1224}>
          <div>
            {/* section 1 */}
            <div className="new-main-cel">
              {/* <div className="new-hero"> */}
              <div className="img-div-new"></div>
              <div className="sh-new-hero-cel"></div>
              {/* <img src="https://d9hhrg4mnvzow.cloudfront.net/try.allblk.tv/e5b5dfc1-header-background-update2_1000000000000000000028.png"></img> */}
              {/* </div> */}
              <div className="new-hero-text-cel">
                <h1>Connecting Cultures and Celebrating Voices</h1>
                <p>
                  Watch popular independent movies, new and old Black Cinema,
                  documentaries and International Cinema from the Back Diaspora.
                </p>
                <a
                  href="#"
                  className="btn btn-danger btn-new-cel"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </div>
            {/* section 2 */}
            <div className="new-main-sec2-cel">
              <div className="sec2-main-cel">
                <div className="sec2-text">
                  <h1>New Releases Every Week</h1>
                  <p>
                    Watch Drama, Documentaries, Music Specials, Faith and
                    Family. All Day, Everyday.
                  </p>
                </div>
                <div className="sec2-image-cel">
                  <img src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/TallImage1.png" />
                </div>

                <a
                  href="#"
                  className="btn btn-danger sec2-btn-new-cel sec2-btn-cel"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </div>
            {/* section 3 */}
            <div className="new-main-cel">
              <div className="sec3-main-cel">
                <h1>World One TV</h1>
                {/* <div className="sec3-info"> */}
                <div className="sec3-info-des-cel">
                  {/* <div className="line"></div> */}
                  <h1>Black Owned </h1>
                  <p>By us, for us</p>
                  <p>Streaming diversity</p>
                  <p>One Click Away</p>
                  <div className="line"></div>
                </div>
                <div className="sec3-info-des-cel">
                  {/* <div className="line"></div> */}
                  <h1>Open Your Mind </h1>
                  <p>
                    Curated to educate, entertain and engage, with enriching
                    content.
                  </p>
                  <div className="line"></div>
                </div>
                <div className="sec3-info-des-cel">
                  {/* <div className="line"></div> */}
                  <h1>Anytime, Anywhere</h1>
                  <p>
                    Download WORLD ONE TV app on your Android and Apple devices.
                  </p>
                  <p>Apple, Fire TV, Roku and Google.</p>

                  {/* <div className="line"></div> */}
                </div>
                {/* </div> */}
                <a
                  href="#"
                  className="btn btn-danger btn-new-cel sec2-btn-cel"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </div>
            {/* section 4 */}
            <div className="new-main-sec2-cel">
              <div className="sec2-main-cel">
                <div className="sec2-text">
                  <h1>Watch the World </h1>
                  <p>
                    World One TV is Free for 30 days just use the promo code
                    FREE30DAYS on our website. After that it’s $2.99 a Month or
                    $39.99 for the year.
                    <br /> Always ad free on worldonetv.com
                  </p>
                </div>
                <div className="sec2-image-cel">
                  <img
                    src="https://streamgomedia.b-cdn.net/SeanReid/WorldOneTV/CourtsideJonesReplacementImage.jpg"
                    width="480px"
                    height="270px"
                  />
                </div>
                <a
                  href=""
                  className="btn btn-danger sec2-btn-new-cel sec4-btn-cel"
                  data-toggle="modal"
                  data-target="#cancel-subs"
                >
                  Start Your Free Trial
                </a>
              </div>
            </div>
            {/* section 5 */}
            <div></div>
            {/* end */}

            {/* modal */}
            <div className="modal fade confirmation-popup" id="cancel-subs">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <form
                    className="auth-form cancel-form"
                    onSubmit={this.handleCancelAutoRenewal}
                  >
                    <div className="modal-header">
                      <h4 className="modal-title">Start Your Free Trial</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="form-group">
                        <p>
                          World One TV is Free for 30 days just use the promo
                          code
                          <div className="coupone-container">
                            <span className="coupon"> FREE30DAYS </span>
                          </div>
                          <br />
                          After that it’s $2.99 a Month or $39.99 for the year
                        </p>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <a
                        href="/subscribe-with-Stripe"
                        className="btn btn-danger "
                      >
                        Start Your Free Trial
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* end modal */}
          </div>
        </MediaQuery>
      </>
    );
  }
}
export default LandingPage;
