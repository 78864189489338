import React, { Component } from "react";
import { Link } from "react-router-dom";

import api from "../../Environment";

import Slider from "../SliderView/MainSlider";
import HomePageBanner from "./homePageBanner";
import ContentLoader from "../Static/contentLoader";

// //check mobile Devices
// import MediaQuery from 'react-responsive'

import {
    // setTranslations,
    // setDefaultLanguage,
    translate
} from "react-multi-lang";
// import en from "../translation/en.json";
// import pt from "../translation/pt.json";

class Home extends Component {
    state = {
        maindata: null,
        errorHandle: 0,
        loading: true,
        banner: null,
        loadingHomeSecondSection: true, // azz
        loadingSeriesSection: true, // azz
        homeSecondData: null, // azz
        seriesData: null, // azz
    };

    componentDidMount() {
        // let str = window.location.href;
        // str = str.split("/");
        // const vidID = str[str.length - 1];
        // alert(vidID);


        const inputData = {
            page_type: "HOME"
        };
        api.postMethod("home_first_section", inputData)
            .then(response => {
                if (response.data.success === true) {
                    let maindata = response.data.data;
                    let banner = response.data.banner;

                    this.setState({
                        loading: false,
                        maindata: maindata,
                        banner: banner
                    });
                } else {
                    let errorHandle = 1;
                    this.setState({ errorHandle });
                }
            })
            .catch(function(error) {});
            
            this.loadSeriasData(inputData)
            
    }
    
     async loadSeriasData(inputData) {
                // Azzam To get all the movies for the home page
           await api
            .postMethod("home_second_section", inputData)
            .then((response) => {
                if (response.data.success === true) {
                console.log("Dandash: ", response.data.Sub_Categories)
                this.setState({
                    loadingSeriesSection: false,
                    seriesData: response.data.data,
                    // seriesData: response.data.Sub_Categories,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
               .catch(function (error) { });
         this.loadSecondData(inputData)
        // End
            }

            async loadSecondData(inputData) {
                // Azzam To get all the movies for the home page
           await api
            .postMethod("home_series_section", inputData)
            .then((response) => {
                if (response.data.success === true) {
                this.setState({
                    loadingHomeSecondSection: false,
                    homeSecondData: response.data.data,
                    homeData: response.data.Sub_Categories,
                });
            } else {
                let errorHandle = 1;
                this.setState({ errorHandle });
            }
            })
               .catch(function (error) { });
                
                // this.loadSeriasData(inputData)
        // End
            }
    
      

    renderVideoList = (maindata, index) => {

        let str = window.location.href;
        str = str.split("/");
        const vidID = str[str.length - 1];

        // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
        // const executeScroll = () => scrollToRef(vidID)

        // if (vidID !== "home") {
        //      executeScroll() 
        // }
        
        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: "HOME",
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>
             {/* { maindata.title === "Friends" ? "" : */}
                        <Slider>                        
                            {maindata.data.map(movie => (
                                <Slider.Item
                                    movie={movie}
                                    key={movie.admin_video_id}
                                    count={maindata.data}
                                    row={maindata.title}
                                    autoOpen={movie.admin_video_id.toString() === vidID ? true : false}
                                    
                                >
                                    item1
                                </Slider.Item>
                            ))}
                    </Slider>
                    
                    {/* } */}
                </div>
            </React.Fragment>
        );
    };

    renderSeriesList = (maindata, index) => {
         let str = window.location.href;
        str = str.split("/");
        const vidID = str[str.length - 1];

        return (
            <React.Fragment key={index}>
                <div className="main-slidersec">
                    <Link
                        to={{
                            pathname: "/view-all",
                            state: {
                                url_type: maindata.url_type,
                                url_type_id: maindata.url_type_id,
                                page_type: "HOME",
                                title: maindata.title
                            }
                        }}
                    >
                        <h3 className="">
                            {maindata.title}
                            <i className="fas fa-angle-right ml-2" />
                        </h3>
                    </Link>

                    <Slider>
                        {maindata.data.map(movie => (
                            <Slider.ItemSeries
                                movie={movie}
                                key={movie.admin_video_id}
                                count={maindata.data}
                                row={maindata.title}
                                autoOpen={movie.admin_video_id.toString() === vidID ? true : false}
                            >
                                item1
                            </Slider.ItemSeries>
                        ))}
                    </Slider>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const { t } = this.props;

        const { loading, maindata, banner, loadingHomeSecondSection, loadingSeriesSection, homeSecondData, seriesData } = this.state;

        return (
            <div className="main-sec-content">
                {loading ? (
                    <ContentLoader />
                ) : (
                     
                            //  <MediaQuery minDeviceWidth={1224}>
                            <HomePageBanner banner={banner} />
                        // </MediaQuery>
                  
                    
                )}
                <div className="main p-40">
                    {/* {renderMyList} */}

                    {loading
                        ? ""
                        : maindata.map((mainDa, index) =>
                              mainDa.data.length === 0
                                  ? ""
                                  : loading
                                  ? t("")
                                  : this.renderVideoList(mainDa, index)
                          )}
                    {/* Azzam */}
                     {loadingSeriesSection
                        ? ""
                        : seriesData.map((mainDa, index) =>                          
                            mainDa.data.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderVideoList(mainDa, index)
                            
                        )}
                    {loadingHomeSecondSection
                        ? ""
                        : homeSecondData.map((mainDa, index) =>                          
                            mainDa.data.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderVideoList(mainDa, index)
                            
                        )}
                    
                    {/* {loadingSeriesSection
                        ? ""
                        : seriesData.map((mainDa, index) =>                          
                            mainDa.data.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderVideoList(mainDa, index)
                            
                        )} */}
                    
                    {/* {loadingSeriesSection
                        ? ""
                        : homeData.map((mainDaa, index) =>
                            mainDaa.length === 0
                            ? ""
                            : loading
                            ? ""
                            : this.renderSeriesList(mainDaa, index)
                        )} */}
                    

                    <div className="height-100" />
                </div>
            </div>
        );
    }
}

export default translate(Home);
