import React, { Component } from "react";
import Header from "../Layouts/SubLayout/StaticHeader";
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import { withToastManager } from "react-toast-notifications";

class DeletedAccount extends Component {
  state = {
    data: {
      id: 1,
      token: "2y10LVpd5sCzdJwNTYFxmIWF1ewYiBI1ddUjMAD3uWm5kiQnyregSo5u",
      email: "",
      password: "",
    },
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
    }));
  };

  handleDelete = (event) => {
    event.preventDefault();
    api.postMethod("deleteAccountGoogle", this.state.data).then((response) => {
      if (response.data.success === true) {
        ToastDemo(this.props.toastManager, response.data.message, "success");
        this.props.history.push("/logout");
      } else {
        ToastDemo(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  render() {
    return (
      <>
        <Header></Header>
        <div>
          <div>
            <div className="main">
              <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
                  <div className="register-box">
                    <h3 className="register-box-head">delete Account</h3>
                    <form
                      onSubmit={this.handleDelete}
                      className="auth-form"
                      action=""
                    >
                      <p className="note">
                        <b>note:</b> Once your account is deleted, you would be
                        losing your history and Wish-List details.
                      </p>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="enter email"
                          name="email"
                          value={this.state.data.email}
                          onChange={this.handleChange}
                        />
                        <input
                          type="password"
                          className="form-control"
                          placeholder="enter password"
                          name="password"
                          value={this.state.data.password}
                          onChange={this.handleChange}
                        />
                      </div>
                      <button className="btn btn-danger auth-btn mt-4">
                        delete account
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withToastManager(DeletedAccount);
