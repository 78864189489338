import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51IsV35LSgOmcpP1GSuuMT82w1gYzVtwkiCYN2tt3auz0wzPZ79UyUxKHgcTPc3OEwoU9MOl9J9X7ggXgQ51y7q6j007aQgAnE5"
);
// const stripePromise = loadStripe('pk_test_51J7p2sDZN8N0VoHEs3DK2cUrPbShLZuZqXgj8vas2KxIkSazC2QKNj9yhqYPkNDq1mlFJlKY1JCVjmY5iS1Q4a2y003FQd3l4b');
ReactDOM.render(
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
