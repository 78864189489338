import React,  { useRef} from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import SliderContext from "./context";
import ShowDetailsButton from "./ShowDetailsButton";
import Mark from "./Mark";
import "./Item.scss";
import Helper from "../../Helper/helper";
const DATE_OPTIONS = {
    year: "numeric",
    month: "short"
};
const focusRef = React.createRef()

class Item extends Helper {
    state = {
        loadingFirst: true,
        videoDetailsFirst: null,
        redirect: false,
        redirectPPV: false,
        redirectPaymentOption: false,
        playButtonClicked: false
    };

    componentDidMount() {
        this.setState({ playButtonClicked: false });
        // let str = window.location.href;
        // str = str.split("/");
        // const vidID = str[str.length - 1];
        // alert(vidID);
    }

    handlePlayVideo = async event => {
        event.preventDefault();

        const inputData = {
            admin_video_id: this.props.movie.admin_video_id,
            skip: 0
        };

        await this.onlySingleVideoFirst(inputData);

        this.redirectStatus(this.state.videoDetailsFirst);
        this.setState({ playButtonClicked: true });
    };

     getTitle(title) {
        return title.replace(/ *\([^)]*\) */g, "");
    };
    render() {
        const { movie, row, autoOpen } = this.props;
       
      

        if (this.state.playButtonClicked) {
            const returnToVideo = this.renderRedirectPage(
                this.state.videoDetailsFirst
            );

            if (returnToVideo != null) {
                return returnToVideo;
            }
        }

        let loaded = false;
        if (row !== "Continue Watching for User" && row !== "Watch List" && autoOpen === true && loaded === false) {
           
            return (
            <SliderContext.Consumer>
              {/* {vid === movie.default_image ?  alert(movie.admin_video_id) : console.log(movie.admin_video_id)} */}
                   
                
        
                {({ onSelectSlide, currentSlide, elementRef }) => {
                     const isActive =
                        currentSlide &&
                         currentSlide.admin_video_id === movie.admin_video_id;
                        
                        
                        if (!loaded) {
                            onSelectSlide(movie)
                            // alert(JSON.stringify(elementRef))
                            // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
                            // scrollToRef(elementRef)
                            //  focusRef.current.scrollIntoView()
                            
                           
                          
                        loaded = true
                        }
                          console.log(focusRef.current)

                        if (focusRef.current == null) {
                           
                            // 
                            //     focusRef.current.focus();
                        } else {
                             focusRef.current.scrollIntoView()
                            }
                        
                       
                    return this.props.count ? this.props.count.length > 2 ? (
                        <div
                            //  id={movie.default_image + row}
                            id={movie.admin_video_id}
                            ref={focusRef}
                            className={cx("item  sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    ) : (
                            <div
                                 //  id={movie.default_image + row}
                                id={movie.admin_video_id}
                            ref={focusRef}
                            className={cx("item sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    ) : (
                            <div
                                 //  id={movie.default_image + row}
                                id={movie.admin_video_id}
                            ref={focusRef}
                            className={cx("item sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    );
                }}
            </SliderContext.Consumer>
        );
        } else {
            return (
            <SliderContext.Consumer>
              {/* {vid === movie.default_image ?  alert(movie.admin_video_id) : console.log(movie.admin_video_id)} */}
                   
                
        
                {({ onSelectSlide, currentSlide, elementRef }) => {
                    const isActive =
                        currentSlide &&
                        currentSlide.admin_video_id === movie.admin_video_id;
                    

                    return this.props.count ? this.props.count.length > 2 ? (
                        <div
                            //  id={movie.default_image + row}
                              id={movie.admin_video_id}
                            ref={elementRef}
                            className={cx("item  sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    ) : (
                            <div
                                 //  id={movie.default_image + row}
                            id={movie.admin_video_id}
                            ref={elementRef}
                            className={cx("item sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    ) : (
                            <div
                                 //  id={movie.default_image + row}
                              id={movie.admin_video_id}
                            ref={elementRef}
                            className={cx("item sliderthumb-text", {
                                "item--open": isActive
                            })}
                            style={{
                                backgroundImage:
                                    "url(" + movie.default_image + ")"
                            }}
                        >
                           
                            <a
                                onClick={() => onSelectSlide(movie)}
                                className="close-overlay"
                            />
                            
                            <div className="width-100 slider-content-box">
                                <Link to="#" onClick={this.handlePlayVideo}>
                                    <div className="thumb-playicon">
                                        <i className="fas fa-play" />
                                    </div>
                                </Link>
                                <h4 className="thumb-title">{this.getTitle(movie.title)}</h4>
                                <h5 className="thumb-details">
                                    {/* <span className="green-clr">
                                        {new Date(
                                            movie.publish_time
                                        ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                        )}
                                    </span> */}
                                    {/* <span className="grey-box">
                                        {movie.age}
                                        <i className="fas fa-plus small" /> {" "} */}
                                    {/* {movie.watch_count}{" "}
                                        <span className="small">Views</span> */}
                                    {/* </span> */}
                                    {/* Not used */}
                                    {movie.should_display_ppv == 1 ? (
                                        <span className="red-box">
                                            {movie.currency} {movie.ppv_amount}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </h5>
                                <p className="thumb-desc">
                                    {movie.description}{" "}
                                </p>
                                <ShowDetailsButton
                                    onClick={() => onSelectSlide(movie)}
                                />
                            </div>
                            {isActive && <Mark />}
                        </div>
                    );
                }}
            </SliderContext.Consumer>
        );
        }

        
    }
}

export default Item;

// const Item = ({ movie }) => (
//   <SliderContext.Consumer>
//     {({ onSelectSlide, currentSlide, elementRef }) => {
//       const isActive =
//         currentSlide && currentSlide.admin_video_id === movie.admin_video_id;

//       return (
//         <div
//           ref={elementRef}
//           className={cx("item sliderthumb-text", {
//             "item--open": isActive
//           })}
//           style={{ backgroundImage: "url(" + movie.default_image + ")" }}
//         >
//           <a onClick={() => onSelectSlide(movie)} className="close-overlay" />
//           <div className="width-100 slider-content-box">
//             <Link to="#" onClick={event => handlePlayVideo(event, movie)}>
//               <div className="thumb-playicon">
//                 <i className="fas fa-play" />
//               </div>
//             </Link>
//             <h4 className="thumb-title">{movie.title}</h4>
//             <h5 className="thumb-details">
//               <span className="green-clr">
//                 {new Date(movie.publish_time).toLocaleDateString(
//                   "en-US",
//                   DATE_OPTIONS
//                 )}
//               </span>
//               <span className="grey-box">
//                 {movie.age}
//                 <i className="fas fa-plus small" /> / 25{" "}
//                 <span className="small">Views</span>
//               </span>
//             </h5>
//             <p className="thumb-desc">{movie.description} </p>
//             <ShowDetailsButton onClick={() => onSelectSlide(movie)} />
//           </div>
//           {isActive && <Mark />}
//         </div>
//       );
//     }}
//   </SliderContext.Consumer>
// );

// export default Item;
